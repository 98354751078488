import React, {  useState } from "react"
import { Drawer, Form, Input, Button, Select, notification } from "antd";
import styles from "./CreateCustomAPIChannel.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCustomAPIChannel } from "src/api/ChannelApi";
import { useSelector } from "react-redux";
import { getActiveOrgId } from "src/store/slices/userSlice";

const CreateCustomAPIChannel = ({isVisible, setShowDrawer, fetch}) => {

  const activeOrgId = useSelector(getActiveOrgId);
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [isValidAllowedOrigins, setIsValidAllowedOrigins] = useState(false);

  const handleChangeSelect = (value) => {
    setAllowedOrigins([...value]);
    setIsValidAllowedOrigins(!(value.length === 0));
  }

  const submitForm = async (formValues) => {
    let data = {
      "organizationId": activeOrgId,
      "sendMessageWebhookURL": formValues?.sendMessageWebhookURL,
      "postLeadSaveWebhookURL": formValues?.postLeadSaveWebhookURL,
      "name":formValues?.channelName,
      "allowedOrigins":[...allowedOrigins],
      "resolveChatWebhookURL": formValues?.resolveChatWebhookURL,
      "reopenChatWebhookURL": formValues?.reopenChatWebhookURL
    };
    try{
      const res = await createCustomAPIChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });
        fetch();
      }
    }catch(err){
      console.log(err);
    }
  }

  const {
    values, 
    touched, 
    errors, 
    handleChange, 
    handleSubmit, 
    isValid,
    // resetForm,
  } = useFormik({
    initialValues:{
      sendMessageWebhookURL: "",
      postLeadSaveWebhookURL:"",
      channelName:"",
      resolveChatWebhookURL: "",
      reopenChatWebhookURL: ""
    },
    validationSchema: Yup.object().shape({
      sendMessageWebhookURL: Yup.string()
        .url("Invalid URL")
        .required("Channel Name is required"),
      postLeadSaveWebhookURL:Yup.string()
        .url("Invalid URL")
        .required("Send Message WebhookURL is required"),
      channelName:Yup.string()
        .required("Channel Name is required"),
      resolveChatWebhookURL: Yup.string()
        .url("Invalid URL")
        .required("Resolve Chat Webhook is required")
    }),
    onSubmit: formValues => {
      submitForm(formValues);
      setShowDrawer(false);
      // resetForm();
    }
  });

  const handleValidationStatus = key => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
        ? "success"
        : touched[key] && errors[key]
          ? "error"
          : "";
  };

  return (
    <>
      <Drawer 
        // width={534} 
        className={styles.CreateCustomAPIChannel__drawer}
        headerStyle={{ "display": "none" }} 
        placement="right" 
        open={isVisible} 
        onClose={() => setShowDrawer(false)} 
      >
        <div className={styles.CreateCustomAPIChannel}>
          <div className={styles.CreateCustomAPIChannel__Header}>  
            Create Custom API Inbox 
          </div>
          <div className={styles.CreateCustomAPIChannel__SubHeader}>
            Setting up a Custom API inbox requires a phone number in which you are willing to create a Custom API channel.
          </div>

          <Form
            layout="vertical"
            className={styles.ChannelForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="channelName"
              label="Channel Name"
              validateStatus={handleValidationStatus("channelName")}
            >
              <Input
                name="channelName"
                placeholder="Enter the Channel Name"
                onChange={handleChange}
                value={values.channelName}
              />
            </Form.Item>

            <Form.Item
              name="sendMessageWebhookURL"
              label="Send Message WebhookURL"
              validateStatus={handleValidationStatus("sendMessageWebhookURL")}
            >
              <Input
                name="sendMessageWebhookURL"
                placeholder="https://google.com"
                onChange={handleChange}
                value={values.sendMessageWebhookURL}
              />
            </Form.Item>

            <Form.Item
              name="postLeadSaveWebhookURL"
              label="Post Lead Save WebhookURL"
              validateStatus={handleValidationStatus("postLeadSaveWebhookURL")}
            >
              <Input
                name="postLeadSaveWebhookURL"
                placeholder="https://google.com"
                onChange={handleChange}
                value={values.postLeadSaveWebhookURL}
              />
            </Form.Item>

            <Form.Item
              name="resolveChatWebhookURL"
              label="Resolve Chat Webhook"
              validateStatus={handleValidationStatus("resolveChatWebhookURL")}>
              <Input
                name="resolveChatWebhookURL"
                placeholder='http://google.com'
                onChange={handleChange}
                value={values.resolveChatWebhookURL}
              />
            </Form.Item>

            <Form.Item
              name="reopenChatWebhookURL"
              label="Reopen Chat Webhook">
              <Input
                name="reopenChatWebhookURL"
                placeholder='http://google.com'
                onChange={handleChange}
                value={values.reopenChatWebhookURL}
              />
            </Form.Item>

            <Form.Item
              name="allowedOrigin"
              label="Allowed Origin"
              validateStatus={handleValidationStatus("allowedOrigin")}
            >              
              <Select
                mode="tags"
                placeholder="https://google.com"
                onChange={handleChangeSelect}
                tokenSeparators={[","]}
              />

            </Form.Item>
            <div className={styles.ChannelForm__ButtonContainer}>
              <Button type="secondary" style={{ border: '1px solid var(--primary-color)'}} onClick={() => setShowDrawer(false)}> Cancel </Button>
              <Button type="primary" disabled={!(isValid && isValidAllowedOrigins)} htmlType="submit" style={isValid || isValidAllowedOrigins ? { backgroundColor: 'var(--primary-color)', color: 'white' } : {}}> Submit </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
}

CreateCustomAPIChannel.propTypes = {};

CreateCustomAPIChannel.defaultProps = {};

export default CreateCustomAPIChannel;