import { gql } from "@apollo/client";

export const STAFF_AND_CHANNEL_SUBSCRIPTION = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      channelData {
        id
        waChannel {
          name
          id
        }
        waDesktopChannel {
          name
          id
        }
      }
      organizationStaff {
        staff {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;

export const CHANNEL_STATUS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      channelData {
        waChannel {
          id
          active
        }
      }
      actionCardFeature
      cueCardFeature
      contactCardFeature
    }
  }
`;

export const LOAD_CHANNELS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      timezone
      channelData {
        waChannel {
          id
          name
          phone
          active
          isoCountryCode
          dialCode
          phone
          authToken
          sid
          active
        }
        waDesktopChannel {
          id
          name
          dialCode
          isoCountryCode
          phone
          active
          vdiUrl
          vdiId
          password
        }
        apiChannel {
          id
          name
          token
          allowedOrigins
          postLeadSaveWebhookURL
          sendMessageWebhookURL
          resolveChatWebhookURL
          reopenChatWebhookURL
          active
        }
        emailChannel {
          id
          name
          active
        }
      }
    }
  }
`;

export const SET_WHATSAPP_CHANNEL_ACTIVE = gql`
  mutation updateWhatsappChannel($patch: UpdateWhatsappChannelInput!) {
    updateWhatsappChannel(input: $patch) {
      whatsappChannel {
        id
        active
      }
    }
  }
`;

export const SET_CUSTOMAPI_CHANNEL_ACTIVE = gql`
  mutation updateApiChannel($patch: UpdateApiChannelInput!) {
    updateApiChannel(input: $patch) {
      apiChannel {
        id
        active
      }
    }
  }
`;

export const SET_EMAIL_CHANNEL_ACTIVE = gql`
  mutation updateEmailChannel($patch: UpdateEmailChannelInput!) {
    updateApiChannel(input: $patch) {
      emailChannel {
        id
        active
      }
    }
  }
`;
